import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ButtonGroupAlignment, ButtonGroupItem, ButtonGroupStylingMode } from '../../models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BreakpointObserverService } from '@klg/shared/utils-dom';

@Component({
  selector: 'kng-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: ButtonGroupItem[];
  @Input() stylingMode: ButtonGroupStylingMode = 'contained';
  @Input() alignment: ButtonGroupAlignment = 'center';
  @Input() grouped: boolean | undefined = false;
  @Input() rounded: boolean | undefined = false;
  @Input() showIconOnSelected: boolean | undefined = false;
  @Input() activeButton: string;
  @Output() buttonClickChanges: EventEmitter<string> = new EventEmitter<string>();

  selectedValue: string;
  isMobile: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(private readonly breakpointObserverService: BreakpointObserverService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserverService.size$.subscribe((breakpoint) => {
        this.isMobile = ['xs'].includes(breakpoint);
      }),
    );
  }

  ngOnChanges({ activeButton }: SimpleChanges) {
    if (activeButton) {
      this.selectedValue = activeButton.currentValue;
    }
  }

  onItemClick(selectedValue): void {
    this.selectedValue = selectedValue;
    this.buttonClickChanges.emit(this.selectedValue);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
