import { inject, Injectable } from '@angular/core';
import { SchoolControllerApiService } from '@klg/shared/api/services';
import { getDateFromString, getDateRangeAroundDate, getDateStringFromDate, isDateWithinRange } from '@klg/shared/utils';
import { BaseService, CachedRequestResponse, Memoize } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductPackageEnum } from '@klg/quote-tool/shared/types';
import { OnlineClassCombo, QuoteCourse } from '@klg/quote-tool/shared/types/course';

export const COURSE_SPAN_DAYS = 60;

@Injectable({
  providedIn: 'root',
})
export class CourseService extends BaseService<QuoteCourse> {
  private responseCache = new CachedRequestResponse<QuoteCourse[]>(() => of([]), this.cacheSettings.get);
  private readonly schoolService = inject(SchoolControllerApiService);

  constructor() {
    super();
  }

  static isCourseInDateRange(course: QuoteCourse, dateRange: [Date, Date]): boolean {
    return !course.fixedCourseDateRanges || course.fixedCourseDateRanges.some(({ startDate }) => isDateWithinRange(getDateFromString(startDate), dateRange));
  }

  static isCourseCompatibleDuration(course: QuoteCourse, duration: number) {
    return (!course.minWeeks || course.minWeeks <= duration) && (!course.maxWeeks || course.maxWeeks >= duration);
  }

  static isCourseSameSchool(course: QuoteCourse, schoolCode: string) {
    return course.schoolCode === schoolCode;
  }

  static isCourseSameLanguage(course: QuoteCourse, languageCode: string) {
    return course.courseLanguage === languageCode;
  }

  @Memoize((residenceCountryCode: string, startDate: string, endDate: string, weeks: number, isJuniors: boolean, quoteDate: string) =>
    [residenceCountryCode, startDate, endDate, weeks, isJuniors, quoteDate].join('|'),
  )
  getAllWithPrice(
    residenceCountryCode: string,
    startDate: string,
    endDate: string,
    weeks: number,
    schoolCode: string,
    isJuniors = false,
    quoteDate?: string,
  ): Observable<QuoteCourse[]> {
    return this.schoolService.findSchoolQuotedCourses(
      quoteDate || getDateStringFromDate(new Date()),
      residenceCountryCode,
      weeks,
      schoolCode,
      this.locale,
      this.companyHeader,
      isJuniors ? ProductPackageEnum.YOUNG_LEARNER_CAMPS : undefined,
      startDate,
      endDate,
    );
  }

  getCoursesWithPriceByFilters(
    residenceCountryCode: string,
    startDate: string,
    endDate: string,
    weeks: number,
    schoolCode: string,
    languageCode: string,
    isJuniors = false,
  ): Observable<QuoteCourse[]> {
    const courseDateRange = getDateRangeAroundDate(getDateFromString(startDate), COURSE_SPAN_DAYS);
    return this.getAllWithPrice(residenceCountryCode, startDate, endDate, weeks, schoolCode, isJuniors).pipe(
      map((courses) =>
        courses.filter(
          (c) =>
            CourseService.isCourseInDateRange(c, courseDateRange) &&
            CourseService.isCourseCompatibleDuration(c, weeks) &&
            CourseService.isCourseSameSchool(c, schoolCode) &&
            CourseService.isCourseSameLanguage(c, languageCode),
        ),
      ),
    );
  }

  getOnlineClassCombos(courseCode: string, schoolCode: string): Observable<OnlineClassCombo[]> {
    return this.schoolService.findOnlineSchoolsClassTimes(schoolCode, courseCode, this.locale, this.companyHeader);
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<QuoteCourse[]>> {
    return of(this.responseCache);
  }
}
