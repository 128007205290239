import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { deepClone } from '@klg/shared/utils';
import { CurrencyService } from '../shared/services/currency.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { getAvailableCurrencyList } from '../shared/functions/currency-price.functions';
import { Currency, CurrencyCode, DESTINATION_CURRENCY_KEY } from '../shared/model/currency.model';
import { Amount } from '@klg/shared/data-access/types';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '@klg/shared/ui/pipes';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CoreComponentsModule } from '@klg/core/components';

@Component({
  selector: 'kng-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, CoreComponentsModule, FilterPipe, DialogModule, ScrollPanelModule],
})
export class CurrencySelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currencyCode: CurrencyCode;
  @Input() defaultCurrency: string;
  @Input() priceCurrencies: Amount[];
  @Input() hideDestinationCurrency: boolean;
  @Output() currencyCodeChange = new EventEmitter<CurrencyCode>(true);
  @Input() invertPalette: boolean;
  public isCurrencySelectorOpen = false;
  public allCurrencies: Currency[] = [];
  public displayCurrencies: Currency[] = [];
  public selectedCurrency: Currency;
  public DESTINATION_CURRENCY_KEY = DESTINATION_CURRENCY_KEY;
  private selectedCurrency$ = new BehaviorSubject<string>(undefined);
  private subscription = new Subscription();
  public closePopup = () => {
    this.isCurrencySelectorOpen = false;
  };

  constructor(private currencyService: CurrencyService) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.currencyService.getAll(), this.selectedCurrency$]).subscribe(([currencies, selectedCurrency]) => {
        this.allCurrencies = currencies.map(deepClone);
        this.displayCurrencies = this.getDisplayCurrencies();
        this.selectedCurrency = this.getCurrencyFromAvailable(selectedCurrency) || this.getDefaultCurrency();
      }),
    );
  }

  ngOnChanges({ currencyCode, defaultCurrency, priceCurrencies }: SimpleChanges): void {
    if (currencyCode?.currentValue && currencyCode.previousValue !== currencyCode.currentValue && currencyCode.currentValue !== DESTINATION_CURRENCY_KEY) {
      this.selectedCurrency$.next(currencyCode.currentValue);
    }

    if (defaultCurrency?.currentValue && defaultCurrency.previousValue !== defaultCurrency.currentValue && this.currencyCode === DESTINATION_CURRENCY_KEY) {
      this.selectedCurrency$.next(DESTINATION_CURRENCY_KEY);
    }

    if (priceCurrencies) {
      this.displayCurrencies = this.getDisplayCurrencies();
    }
  }

  private getDefaultCurrency(): Currency {
    return (this.defaultCurrency && this.getCurrencyFromAvailable(this.defaultCurrency)) || (this.displayCurrencies && this.displayCurrencies[0]);
  }

  private getDisplayCurrencies(): Currency[] {
    return getAvailableCurrencyList(this.allCurrencies, this.priceCurrencies);
  }

  private getCurrencyFromAvailable(currencyCode: string): Currency {
    return this.displayCurrencies?.find((currency) => currency.code === currencyCode);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public selectCurrency(currency: Currency) {
    if (currency && currency.code) {
      this.selectedCurrency = currency;
      this.currencyCode = currency.code;
      this.isCurrencySelectorOpen = false;
      this.currencyCodeChange.emit(currency.code);
    }
  }

  public handleDropdownClick() {
    if (this.currencyCode === DESTINATION_CURRENCY_KEY) {
      this.selectCurrency(this.selectedCurrency || this.getDefaultCurrency());
    } else {
      this.isCurrencySelectorOpen = true;
    }
  }

  public handleDestinationClick() {
    this.currencyCode = DESTINATION_CURRENCY_KEY;
    this.currencyCodeChange.emit(DESTINATION_CURRENCY_KEY);
    return;
  }
}
