/* eslint-disable @nx/enforce-module-boundaries */
import { Pipe, PipeTransform } from '@angular/core';
import { SortingCriteria, SortDirection } from '@klg/shared/types';
import { sortArray } from '@klg/shared/utils-array';

@Pipe({ name: 'klgSortBy', standalone: true })
export class SortByPipe implements PipeTransform {
  transform<T>(originalArray: T[], sortCriteria: SortingCriteria<T> | SortDirection = 'asc'): T[] {
    return sortArray<T>(originalArray, sortCriteria);
  }
}
