import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { EnrolmentPaymentComponent } from './enrolment-payment.component';

@Injectable({
  providedIn: 'root',
})
export class EnrolmentPaymentResolver  {
  private stepDefinition: StepDefinition = {
    stepId: ROUTE_IDS.BOOKING_ENROLMENT_PAYMENT,
    displayQuote: true,
    previousStep: () => ROUTE_IDS.BOOKING_FORM,
  };

  resolve(): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: ROUTE_IDS.ENROLMENT_PAYMENT,
  resolve: {
    stepDefinition: EnrolmentPaymentResolver,
  },
  component: EnrolmentPaymentComponent,
};
