<p-accordion
  (activeIndexChange)="activeIndexChange($event)"
  (onClose)="showSummary($event?.index)"
  (onOpen)="hideSummary($event?.index)"
  [activeIndex]="activeIndex"
  [expandIcon]="' '"
  [collapseIcon]="' '"
>
  <p-accordionTab iconPos="end">
    <ng-template pTemplate="header">
      <div class="price-request-form-accordion__tab-header">
        <div class="price-request-form-accordion__tab-header__header">
          <div class="price-request-form-accordion__tab-header__header__title" i18n>Program and school</div>
          <div class="price-request-form-accordion__tab-header__header__icon">
            <i class="pi" [ngClass]="{ 'pi-circle-off': (courseFormValid$ | async) === false, 'pi-check-circle': (courseFormValid$ | async) === true }"></i>
          </div>
        </div>

        <ng-container *ngIf="quoteInputSummary.length > 0 && quoteInputSummaryVisible">
          <div class="price-request-form-accordion__tab-header__info-panel">
            <div class="price-request-form-accordion__tab-header__info-panel__summary-info">
              {{ quoteInputSummary }}
            </div>
            <div class="price-request-form-accordion__tab-header__info-panel__edit-button">
              <kng-button
                id="btn-edit-course-form"
                data-qa="qt-edit-course-form"
                i18n-text
                text="Edit"
                [uppercase]="true"
                [buttonStyle]="'link'"
                (buttonClick)="editForm($event, formIndexes.courseForm)"
              >
              </kng-button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <kng-price-request-course-form [columns]="1"></kng-price-request-course-form>
      <div class="price-request-form-accordion__button">
        <kng-button
          id="btn-next"
          data-qa="qt-next-button"
          icon="pi pi-chevron-down"
          i18n-text
          text="Next"
          [style]="{ height: '50px', width: '264px' }"
          [hasText]="true"
          [block]="true"
          iconPos="after"
          [disabled]="(courseFormValid$ | async) === false"
          [uppercase]="true"
          [buttonStyle]="'primary'"
          (buttonClick)="activeIndexChange(formIndexes.studentForm)"
        >
        </kng-button>
      </div>
    </ng-template>
  </p-accordionTab>
  <p-accordionTab iconPos="end" [disabled]="(courseFormValid$ | async) === false">
    <ng-template pTemplate="header">
      <div class="price-request-form-accordion__tab-header">
        <div class="price-request-form-accordion__tab-header__header">
          <div class="price-request-form-accordion__tab-header__header__title" i18n>Your details</div>
          <div class="price-request-form-accordion__tab-header__header__icon">
            <i class="pi" [ngClass]="{ 'pi-circle-off': (studentFormValid$ | async) === false, 'pi-check-circle': (studentFormValid$ | async) === true }"></i>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="studentDetailsTemplate" [ngTemplateOutletContext]="{ studentDetails: studentDetails$ | async }"></ng-container>

        <ng-template #studentDetailsTemplate let-studentDetails="studentDetails">
          <ng-container *ngIf="filledStudentDetails && studentDetailsSummaryVisible">
            <div class="price-request-form-accordion__tab-header__info-panel">
              <div class="price-request-form-accordion__tab-header__info-panel__summary-info">
                <div class="price-request-form-accordion__tab-header__info-panel__summary-info__student-details">
                  <div class="price-request-form-accordion__tab-header__info-panel__summary-info__student-details__name">
                    {{ studentDetails?.firstName }} {{ studentDetails?.lastName }}
                  </div>
                  <div class="price-request-form-accordion__tab-header__info-panel__summary-info__student-details__contact">
                    {{ studentDetailsContactInfoSummary }}
                  </div>
                  <div class="price-request-form-accordion__tab-header__info-panel__summary-info__student-details__address">
                    {{ studentDetailsAddressSummary }}
                  </div>
                </div>
              </div>
              <div class="price-request-form-accordion__tab-header__info-panel__edit-button">
                <kng-button
                  id="btn-edit-student-form"
                  data-qa="qt-edit-student-form"
                  i18n-text
                  text="Edit"
                  [uppercase]="true"
                  [buttonStyle]="'link'"
                  (buttonClick)="editForm($event, formIndexes.studentForm)"
                >
                </kng-button>
              </div>
            </div>
            <kng-button
              *ngIf="(studentFormValid$ | async) === true"
              class="price-request-form-accordion__tab-header__send-enquiry-button"
              id="btn-send-enquiry-from-header"
              data-qa="qt-send-enquiry-from-header"
              icon="pi pi-chevron-right"
              i18n-text
              text="Send enquiry"
              [style]="{ height: '72px' }"
              [hasText]="true"
              [block]="true"
              iconPos="after"
              [buttonStyle]="'primary'"
              (buttonClick)="sendEnquiry()"
            >
            </kng-button>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <kng-price-request-student-form [columns]="1" (validForm)="studentFormValid$.next($event)"></kng-price-request-student-form>
      <div class="price-request-form-accordion__button">
        <kng-button
          id="btn-send-enquiry"
          data-qa="qt-send-enquiry"
          icon="pi pi-chevron-right"
          i18n-text
          text="Send enquiry"
          [style]="{ height: '50px', width: '264px' }"
          [hasText]="true"
          [block]="true"
          iconPos="after"
          [disabled]="(studentFormValid$ | async) === false"
          [uppercase]="true"
          [buttonStyle]="'primary'"
          (buttonClick)="sendEnquiry()"
        >
        </kng-button>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
