import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { FormType, ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { QuoteFormDataResolver } from './quote-form-data.resolver';
import { QuoteFormComponent } from './quote-form.component';
import { PriceRequestFormAccordionComponent } from './price-request-form-experiments/price-request-form-accordion/price-request-form-accordion.component';
import { PRICE_REQUEST_EXPERIMENT_B, PRICE_REQUEST_EXPERIMENT_C, PRICE_REQUEST_EXPERIMENT_D } from '../../../experiments/experiment-id.constants';
import { PriceRequestFormSimplifiedComponent } from './price-request-form-experiments/price-request-form-simplified/price-request-form-simplified.component';

type FormTypeQuoteStepDefinition = {
  [key in Exclude<FormType, FormType.WIZARD>]: StepDefinition;
};

@Injectable({
  providedIn: 'root',
})
export class QuoteFormResolver  {
  private quoteStepDefinitions: FormTypeQuoteStepDefinition = {
    [FormType.FREE_QUOTE]: {
      nextButton: $localize`Send to my email`,
      stepId: ROUTE_IDS.QUOTE_FORM,
      displayQuote: true,
      nextStep: () => ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
    },
    [FormType.ENROLLMENT]: {
      nextButton: $localize`Next`,
      stepId: ROUTE_IDS.QUOTE_FORM,
      displayQuote: true,
      nextStep: () => ROUTE_IDS.BOOKING_FORM,
    },
    [FormType.PRICE_REQUEST]: {
      nextButton: $localize`Send enquiry`,
      stepId: ROUTE_IDS.QUOTE_FORM,
      displayQuote: false,
      nextStep: () => ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
      experiments: [
        {
          experimentId: PRICE_REQUEST_EXPERIMENT_B,
          experimentComponent: PriceRequestFormSimplifiedComponent,
          hideCtas: false,
        },
        {
          experimentId: PRICE_REQUEST_EXPERIMENT_C,
          experimentComponent: PriceRequestFormAccordionComponent,
          hideCtas: true,
        },
        {
          experimentId: PRICE_REQUEST_EXPERIMENT_D,
          experimentComponent: PriceRequestFormAccordionComponent,
          hideCtas: true,
        },
      ],
    },
    [FormType.QUICK_QUOTE_TOOL]: {
      nextButton: $localize`Save for ARGO`,
      stepId: ROUTE_IDS.QUOTE_FORM,
      displayQuote: true,
      hideCtas: true,
      submitStep: true,
    },
  };

  constructor(private stepService: StepService) {}

  resolve(): StepDefinition {
    return this.quoteStepDefinitions[this.stepService.getFormType()];
  }
}

export const stepRoute: Route = {
  path: ROUTE_IDS.QUOTE_FORM,
  resolve: {
    stepDefinition: () => inject(QuoteFormResolver).resolve(),
    data: () => inject(QuoteFormDataResolver).resolve(),
  },
  component: QuoteFormComponent,
};

export const rootRoute: Route = {
  ...stepRoute,
  path: ROUTE_IDS.FORM_ROOT,
};
