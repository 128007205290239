// eslint-disable-next-line @nx/enforce-module-boundaries
import { ObjectValues } from '@klg/shared/utils-ts';

export const COMPANIES = {
  ALPADIA: 'alpadia',
  ESL: 'esl',
  KAPLAN: 'kaplan',
  KSG: 'ksg',
} as const;

export type Company = ObjectValues<typeof COMPANIES>;
export type CompanyKey = keyof typeof COMPANIES;
