import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { QuoteErrorComponent } from './quote-error.component';

const stepId = ROUTE_IDS.QUOTE_ERROR;

@Injectable({
  providedIn: 'root',
})
export class QuoteErrorResolver  {
  private stepDefinition: StepDefinition = {
    stepId,
    previousStep: () => ROUTE_IDS.QUOTE_FORM,
  };

  resolve(): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: stepId,
  resolve: {
    stepDefinition: () => inject(QuoteErrorResolver).resolve(),
  },
  component: QuoteErrorComponent,
};
