<div class="terms-and-promotions">
  <div class="terms-and-promotions__checkbox-container">
    <div class="terms-and-promotions__checkbox-container__box">
      <p-checkbox
        data-qa="qt-terms-and-promotions-checkbox"
        [value]="sectionModel.agreeToPromotions"
        [binary]="true"
        [(ngModel)]="sectionModel.agreeToPromotions"
        (ngModelChange)="updateAgreeToPromotions()"
      ></p-checkbox>
    </div>
    <div class="terms-and-promotions__checkbox-container__text" i18n>
      We'd like to keep in touch to share the latest news and special offers from ESL. If this isn't for you, tick the box to opt out.
    </div>
  </div>
  <div *ngIf="isEnrollmentFormType()" class="terms-and-promotions__checkbox-container">
    <div class="terms-and-promotions__checkbox-container__box">
      <p-checkbox
        data-qa="qt-terms-and-conditions-checkbox"
        [value]="sectionModel.agreeToTerms"
        [binary]="true"
        [(ngModel)]="sectionModel.agreeToTerms"
        (ngModelChange)="updateAgreeToTerms()"
      ></p-checkbox>
    </div>
    <div class="terms-and-promotions__checkbox-container__text">
      <span
        (click)="sectionModel.agreeToTerms = !sectionModel.agreeToTerms"
        (mouseenter)="isTermsTextHovered = true"
        (mouseleave)="isTermsTextHovered = false"
        class="qt-form__check-box-label"
        i18n
        >I completely agree with the <a [href]="termsAndConditionsLink" class="terms-and-promotions__terms-link" target="_blank">terms and conditions</a> of ESL
      </span>
    </div>
  </div>
  <div class="terms-and-promotions__privacy-information">
    <span i18n
      >We will use your phone number to call or message you about your enquiry. To find out more about how we handle and share your personal data see our
    </span>
    <a [href]="moreInfoLink" class="terms-and-promotions__privacy-information-link" i18n target="_blank">Privacy Policy</a>.
  </div>
</div>
